import React from 'react';
import { FormControl, Grid, Hidden, TextField, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import utils, { validateNIF, validateCIF } from '../../../helpers/validations'
import { factory } from '../../../helpers/factory';
import Spinner from '../../shared/spinner';
import MaterialTableExtend from '../../material-table';
import { history } from '../../../helpers/history';
import { allowAccessRole } from '../../../helpers/roles'
import { green } from '@material-ui/core/colors'
import { Lens } from '@material-ui/icons'
import ErrorIcon from '@material-ui/icons/Error'
import { MODULES, OPERATIONS_ALLOWED } from '../../roles/constants';

const filter = createFilterOptions();

const styles = makeStyles((theme) => ({
    ' & .MuiFormControl-root': {
        width: '100%',
    },
}));

class DriverFormView extends React.Component {

    constructor(props) {
        super()
        const driver = props.driver ? props.driver : {}
        this.state = {
            id: driver.id || '',
            name: driver.name || '',
            surname1: driver.surname1 || '',
            surname2: driver.surname2 || '',
            email: driver.email || '',
            identityDocumentCode: driver.identityDocumentCode || '',
            cif: driver.cif || '',
            phoneNumber: driver.phoneNumber || '',
            otpId: driver.otpId ? { 'id': driver.otpId, 'name': driver.otpName } : '',
            otpNewName: "",
            otpVatNumber: "",
            otpContactPerson: "",
            otpEmail: "",
            otpPhoneNumber: "",
            errors: {
                name: { 'result': false, 'message': '' },
                surname1: { 'result': false, 'message': '' },
                surname2: { 'result': true, 'message': '' },
                email: { 'result': false, 'message': '' },
                identityDocumentCode: { 'result': false, 'message': '' },
                cif: { 'result': true, 'message': '' },
                phoneNumber: { 'result': props.detail ? true : false, 'message': '' },
                otpId: { 'result': false, 'message': '' },
                vehicle: { 'result': true, 'message': '' },
                otpNewName: { result: true, message: "" },
                otpVatNumber: { result: true, message: "" },
                otpEmail: { result: true, message: "" },
                otpContactPerson: { result: true, message: "" },
                otpPhoneNumber: { result: true, message: "" },
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.errors;
        errors[name].message = "";
        this.setState({ [name]: value })
    }

    onClickBack() {
        history.goBack()
    }

    validateForm() {
        let errors = this.state.errors;
        errors.name = utils.validateName(this.state.name);
        if (errors.name.result)
            errors.name = utils.validateMinLength(this.state.name, 2);
        errors.surname1 = utils.validateLastName(this.state.surname1);
        errors.surname2 = this.state.surname2 !== '' ? utils.validateLastName(this.state.surname2) : { result: true, message: '' };
        errors.email = utils.validateEmail(this.state.email);
        if (!this.props.detail) {
            errors.phoneNumber = utils.required(this.state.phoneNumber);
            if (errors.phoneNumber.result)
                errors.phoneNumber = utils.validatePhone(this.state.phoneNumber);
            if (this.state.otpId.id === "createOTP") {
                errors.otpId = { result: true, message: "" };
                errors.otpNewName = utils.required(this.state.otpNewName);
                errors.otpVatNumber = utils.validateCIF_Nif(this.state.otpVatNumber);
                if (!errors.otpVatNumber.result) {
                    errors.otpVatNumber = utils.validateNIF(this.state.otpVatNumber);
                }
                errors.otpContactPerson = utils.required(this.state.otpContactPerson);
                errors.otpPhoneNumber = utils.required(this.state.otpPhoneNumber);
                if (errors.otpPhoneNumber.result && !this.props.detail) {
                    errors.otpPhoneNumber = utils.validatePhone(this.state.otpPhoneNumber);
                }
                errors.otpEmail = utils.required(this.state.otpEmail);
                if (errors.otpEmail.result)
                    errors.otpEmail = utils.validateEmail(this.state.otpEmail);
            } else {
                errors.otpId = utils.required(this.state.otpId);
                errors.otpNewName = { result: true, message: "" };
                errors.otpVatNumber = { result: true, message: "" };
                errors.otpContactPerson = { result: true, message: "" };
                errors.otpPhoneNumber = { result: true, message: "" };
                errors.otpEmail = { result: true, message: "" };
            }
        } else {
            errors.otpId = { result: true, message: "" };
        }

        errors.identityDocumentCode = { result: true, message: '' }; 
        errors.cif = { result: true, message: '' };

        if (this.state.cif) {
            errors.cif = validateCIF(this.state.cif);
        } else {
            errors.identityDocumentCode = utils.requiredNifCif(this.state.identityDocumentCode);
            if (errors.identityDocumentCode.result) {
                errors.identityDocumentCode = validateNIF(this.state.identityDocumentCode);
            }
        }
    
        this.setState({ errors });
    }

    isFormValid() {
        let valid = true;
        let properties = Object.getOwnPropertyNames(this.state.errors);
        properties.forEach(element => {
            if (!this.state.errors[element].result) {
                valid = false;
            }
        });
        return valid;
    }

    onChangeOtp = (event, newValue) => {
        let errors = this.state.errors;
        errors.otpId.message = ''
        this.setState({
            otpId: newValue,
            vehicle: []
        })
    }

    onChangeVehicles = (event, newValue) => {
        let errors = this.state.errors;
        errors.vehicle.message = ''
        this.setState({
            vehicle: newValue
        })
    }


    onSubmit = (event) => {
        event.preventDefault();
        this.validateForm();
        if (this.isFormValid()) {
            const otp = {
                name: this.state.otpNewName,
                vatNumber: this.state.otpVatNumber,
                contactPerson: this.state.otpContactPerson,
                phoneNumber: this.state.otpPhoneNumber,
                email: this.state.otpEmail
            }
            const driver = factory.createDriver(
                this.state.id,
                this.state.email,
                this.state.name,
                this.state.surname1,
                this.state.surname2,
                this.state.identityDocumentCode,
                this.state.cif,
                this.state.otpId.id != 'createOTP' ? this.state.otpId.id : null,
                this.state.phoneNumber,
                this.state.otpId.name,
                this.state.otpId && this.state.otpId.id == 'createOTP',
                otp
            )
            this.props.sendDriver(driver)
        }
    }



    render() {
        const { t, driverReducer, otpReducer, vehicleReducer } = this.props;
        const otps = otpReducer.results

        const getOTPSelectorOptions = () => {
            return allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.manage) ? [{ id: "createOTP", name: t('vehicles.form.page.createOTP') }, ...otps] : otps
        }
        const readOnly = this.props.readOnly || this.props.readEdit
        return (
            <React.Fragment>
                <Spinner loading={driverReducer.pending} />
                <h2 style={{ padding: 50 }}>{this.props.title}</h2>
                <form onSubmit={this.onSubmit} autoComplete="off">
                    <Grid className={styles.root} container spacing={3}>
                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={12} sm={6} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('drivers.form.page.name')}
                                    value={this.state.name || ''}
                                    variant="outlined"
                                    name="name"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.name.message.length !== 0}
                                    helperText={this.state.errors.name.message}
                                    inputProps={{ readOnly: readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('drivers.form.page.surname1')}
                                    value={this.state.surname1 || ''}
                                    variant="outlined"
                                    name="surname1"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.surname1.message.length !== 0}
                                    helperText={this.state.errors.surname1.message}
                                    inputProps={{ readOnly: readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('drivers.form.page.surname2')}
                                    value={this.state.surname2 || ''}
                                    variant="outlined"
                                    name="surname2"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.surname2.message.length !== 0}
                                    helperText={this.state.errors.surname2.message}
                                    inputProps={{ readOnly: readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} implementation="css" smDown component={Hidden} />


                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={12} sm={6} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('drivers.form.page.document')}
                                    value={this.state.identityDocumentCode || ''}
                                    variant="outlined"
                                    name="identityDocumentCode"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.identityDocumentCode.message.length !== 0}
                                    helperText={this.state.errors.identityDocumentCode.message}
                                    inputProps={{ readOnly: readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('drivers.form.page.email')}
                                    value={this.state.email || ''}
                                    variant="outlined"
                                    name="email"
                                    fullWidth
                                    onChange={this.handleChange}
                                    type={'email'}
                                    error={this.state.errors.email.message.length !== 0}
                                    helperText={this.state.errors.email.message}
                                    inputProps={{ readOnly: readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('drivers.form.page.phone')}
                                    value={this.state.phoneNumber || ''}
                                    variant="outlined"
                                    name="phoneNumber"
                                    fullWidth
                                    type={'tel'}
                                    onChange={this.handleChange}
                                    error={this.state.errors.phoneNumber.message.length !== 0}
                                    helperText={this.state.errors.phoneNumber.message}
                                    inputProps={{ readOnly: readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} implementation="css" smDown component={Hidden} />


                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={12} sm={6} >
                            <FormControl fullWidth>
                                <TextField
                                    label={t('drivers.form.page.cif')}
                                    value={this.state.cif || ''}
                                    variant="outlined"
                                    name="cif"
                                    fullWidth
                                    onChange={this.handleChange}
                                    error={this.state.errors.cif.message.length !== 0}
                                    helperText={this.state.errors.cif.message}
                                    inputProps={{ readOnly: readOnly }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6} >
                        <FormControl fullWidth>
                                <Autocomplete
                                    id="otpId"
                                    value={this.state.otpId || ''}
                                    onChange={this.onChangeOtp}
                                    options={getOTPSelectorOptions()}
                                    getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                                    getOptionLabel={(option) => option.name || factory.getLabelForName(otps, this.state.otpId)}
                                    disabled={readOnly}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant={'outlined'}
                                            label={t('drivers.form.page.otp')}
                                            helperText={this.state.errors.otpId.message}
                                            name="otpId"
                                            error={this.state.errors.otpId.message.length !== 0}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12} sm={6} >
                            
                        </Grid>
                        <Grid item md={2} implementation="css" smDown component={Hidden} />


                        <Grid item md={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={3} xs={12} sm={6}>
                            
                        </Grid>
                        <Grid item md={8} implementation="css" smDown component={Hidden} />

                        {
                            this.state.otpId && this.state.otpId.id == "createOTP" && this.props.nextAction == "create" && allowAccessRole(MODULES.OTP, OPERATIONS_ALLOWED.manage) && (
                                <React.Fragment>
                                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                                    <Grid item md={3} xs={12} sm={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.name')}
                                                value={this.state.otpNewName || ''}
                                                variant="outlined"
                                                name="otpNewName"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={this.state.errors.otpNewName.message.length !== 0}
                                                helperText={this.state.errors.otpNewName.message}
                                                inputProps={{ readOnly: readOnly }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12} sm={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.vatNumber')}
                                                value={this.state.otpVatNumber || ''}
                                                variant="outlined"
                                                name="otpVatNumber"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={this.state.errors.otpVatNumber.message.length !== 0}
                                                helperText={this.state.errors.otpVatNumber.message}
                                                inputProps={{ readOnly: readOnly }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12} sm={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.contact')}
                                                value={this.state.otpContactPerson || ''}
                                                variant="outlined"
                                                name="otpContactPerson"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={this.state.errors.otpContactPerson.message.length !== 0}
                                                helperText={this.state.errors.otpContactPerson.message}
                                                inputProps={{ readOnly: readOnly }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} implementation="css" smDown component={Hidden} />


                                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                                    <Grid item md={3} xs={12} sm={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.email')}
                                                value={this.state.otpEmail || ''}
                                                variant="outlined"
                                                name="otpEmail"
                                                fullWidth
                                                onChange={this.handleChange}
                                                error={this.state.errors.otpEmail.message.length !== 0}
                                                helperText={this.state.errors.otpEmail.message}
                                                inputProps={{ readOnly: readOnly }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} xs={12} sm={6} >
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t('otp.form.page.phone')}
                                                value={this.state.otpPhoneNumber || ''}
                                                variant="outlined"
                                                name="otpPhoneNumber"
                                                fullWidth
                                                type={'tel'}
                                                onChange={this.handleChange}
                                                error={this.state.errors.otpPhoneNumber.message.length !== 0}
                                                helperText={this.state.errors.otpPhoneNumber.message}
                                                inputProps={{ readOnly: readOnly }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} implementation="css" smDown component={Hidden} />
                                </React.Fragment>
                            )
                        }


                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />
                        <Grid item md={2} xs={12}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={this.onClickBack}
                            >
                                {this.props.goBack}
                            </Button>
                        </Grid>
                        <Grid item md={1} xs={4} implementation="css" smDown component={Hidden} />
                        {
                            allowAccessRole(MODULES.Drivers, OPERATIONS_ALLOWED.manage) &&
                            <Grid item md={2} xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={driverReducer.pending}
                                    fullWidth
                                    size="large">
                                    {this.props.buttonRight}
                                </Button>
                            </Grid>
                        }
                        <Grid item md={3} xs={1} implementation="css" smDown component={Hidden} />


                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}

export default DriverFormView;