import { notificationsConstants } from '../../constants/notifications_constants'

let initialState = {
    pending: false,
    isFetchLoading: false,
    results: [],
    deviationNotificationTypes: [],
    isDeviationTypeLoading: false,
    deviations: [],
    isDeviationLoading: false,
    notificationTypes: [],
    uncheckedNotifications: 0,
}

export function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case notificationsConstants.GETALL_REQUEST:
            return {
                ...state,
                isFetchLoading: true,
            }
        case notificationsConstants.GETALL_SUCCESS:
            return {
                ...state,
                isFetchLoading: false,
                results: action.results,
            }
        case notificationsConstants.GETALL_FAILURE:
            return {
                ...state,
                isFetchLoading: false,
                results: [],
                error: action.error,
            }
        case notificationsConstants.TYPES_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case notificationsConstants.TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                notificationTypes: action.notificationTypes,
            }
        case notificationsConstants.TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }

        case notificationsConstants.GET_DEVIATION_REQUEST:
            return {
                ...state,
                isDeviationLoading: true
            }

        case notificationsConstants.GET_DEVIATION_SUCCESS:
            return {
                ...state,
                isDeviationLoading: false,
                deviations: action.results
            }

        case notificationsConstants.GET_DEVIATION_FAILURE:
            return {
                ...state,
                isDeviationLoading: false,
                deviations: [],
                error: action.error,
            }


        case notificationsConstants.DELETE_LIST_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case notificationsConstants.DELETE_LIST_SUCCESS: {
            return {
                ...state,
                pending: false,
                results: state.results.filter((el) => !action.idList.includes(el.id)),
            }
        }

        case notificationsConstants.DELETE_LIST_FAILURE: {
            return {
                ...state,
                pending: false,
                error: action.error
            }
        }


        case notificationsConstants.DELETE_DEVIATION_LIST_REQUEST:
            return {
                ...state,
                pending: true,
            }

        case notificationsConstants.DELETE_DEVIATION_LIST_SUCCESS: {
            return {
                ...state,
                pending: false,
                deviations: state.deviations.filter((el) => !action.idList.includes(el.id)),
            }
        }

        case notificationsConstants.DELETE_DEVIATION_LIST_FAILURE: {
            return {
                ...state,
                pending: false,
                deviations: action.error
            }
        }


        case notificationsConstants.GET_NOTIFICATIONS_COUNT:
            return {
                ...state,
                uncheckedNotifications: action.results,
            }
        case notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                pending: true
            }
        case notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.notifications
            }
        case notificationsConstants.GET_CUSTOMER_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case notificationsConstants.GET_DEVIATION_NOTIFICATION_TYPES_REQUEST:
            return {
                ...state,
                isDeviationTypeLoading: true,
            }
        case notificationsConstants.GET_DEVIATION_NOTIFICATION_TYPES_SUCCESS:
            return {
                ...state,
                deviationNotificationTypes: action.notificationTypes,
                isDeviationTypeLoading: false,
            }
        case notificationsConstants.GET_DEVIATION_NOTIFICATION_TYPES_FAILURE:
            return {
                ...state,
                isDeviationTypeLoading: false,
                error: action.error,
            }
        default:
            return state
    }
}
