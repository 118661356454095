import { customerConstants } from '../../constants/customer_constants'
import { servicesConstants } from '../../constants/services_constants'
import { tripConstants } from '../../constants/trip_constants'
import { userConstants } from '../../constants/user_constants'

let initialState = {
    pending: false,
    results: [],
    customer: null,
    relatedCustomers: [],
    customerAddresses: [],
    isSubmittingGDPR: false,
    favouriteAddresses: [],
    customersKPI: [],
    currentPage: 0,
    documentTypes: [],
    tableState: [],
    isMenuOpen: true,
}

export function customerReducer(state = initialState, action) {
    switch (action.type) {
        case customerConstants.FILTER_REQUEST:
        case customerConstants.ADD_REQUEST:
        case customerConstants.DELETE_REQUEST:
        case customerConstants.RESET_PSW_REQUEST:
        case customerConstants.CHECK_PROFILE_REQUEST:
        case customerConstants.UPDATE_REQUEST:
        case customerConstants.CUSTOMER_GET_KPI_REQUEST:
        case customerConstants.GET_DOCUMENT_TYPES_REQUEST:
            return {
                ...state,
                pending: true,
                error: null,
            }
        case customerConstants.GETBYID_REQUEST:
            return {
                ...state,
                pending: true,
                customer: null,
            }
        case customerConstants.GETALL_REQUEST:
            return {
                ...state,
                pending: true,
                results: [],
            }
        case customerConstants.FILTER_SUCCESS:
        case customerConstants.GETALL_SUCCESS:
            return {
                ...state,
                pending: false,
                results: action.results,
            }
        case customerConstants.FILTER_FAILURE:
        case customerConstants.GETALL_FAILURE:
            return {
                ...state,
                pending: false,
                results: [],
                error: action.error,
            }
        case servicesConstants.SET_ABSENCES_SUCCESS:
            const customer = state.customer
            customer.absences = action.absences
            return {
                ...state,
            }
        case customerConstants.GETBYID_SUCCESS:
            return {
                ...state,
                customer: action.customer,
                relatedCustomers: action.customer.relatedCustomers,
                pending: false,
            }
        case customerConstants.GETBYID_FAILURE:
        case customerConstants.ADD_FAILURE:
        case customerConstants.DELETE_FAILURE:
        case customerConstants.CHECK_PROFILE_FAILURE:
        case customerConstants.UPDATE_FAILURE:
        case customerConstants.CUSTOMER_GET_KPI_FAILURE:
        case customerConstants.GET_DOCUMENT_TYPES_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case customerConstants.CLEAR_RESULTS:
            return {
                ...state,
                results: [],
                pending: false,
            }
        case customerConstants.ADD_SUCCESS:
        case customerConstants.UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                customer: action.customer,
            }
        case customerConstants.DELETE_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(x => x.id !== action.id),
            }
        case userConstants.ADD_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: [...state.relatedCustomers, action.customer],
            }
        case userConstants.REMOVE_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.filter(
                    item => item.tableData.id != action.customer.tableData.id,
                ),
            }
        case userConstants.EDIT_RELATEDCUSTOMER:
            return {
                ...state,
                relatedCustomers: state.relatedCustomers.map((content, i) =>
                    action.customer.tableData.id === content.tableData.id
                        ? { ...action.customer }
                        : content,
                ),
            }
        case userConstants.OPEN_MODAL:
            return {
                ...state,
                add: action.typeOperation === 'add',
                edit: action.typeOperation === 'edit',
                userEdit: action.typeOperation === 'edit' ? action.user : null,
            }
        case customerConstants.SET_CUSTOMER:
            return {
                ...state,
                currentCustomer: action.id,
                countMax: action.countMax,
                fullName: action.fullName,
                address: action.address,
                name: action.name,
                surname: action.surname,
                favouriteAddresses: action.favouriteAddresses,
                customerBirthDate: action.customerDateOfBirth,
                relatedCustomers: action.relatedCustomers,
                langNotification: action.lang,
                customerPushToken: action.pushToken,
                hasWheelChair: action.hasWheelChair,
            }
        case customerConstants.REFRESH_CUSTOMER:
            return {
                ...state,
                pending: false,
                favouriteAddresses: action.customer.favouriteAddresses,
                address: action.customer.addressPosition,
            }
        case customerConstants.RESET_PSW_SUCCESS:
        case customerConstants.RESET_PSW_FAILURE:
            return {
                ...state,
                pending: false,
            }
        case customerConstants.CHECK_PROFILE_SUCCESS:
            return {
                ...state,
                pending: false,
                canOperate: action.result,
            }
        case userConstants.GET_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                isSubmittingGDPR: true,
            }
        case userConstants.GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                isSubmittingGDPR: false,
                GDPRurlFile: action.data,
            }
        case userConstants.GET_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                isSubmittingGDPR: false,
                error: action.error,
            }
        case customerConstants.CUSTOMER_ADDRESSES_SUCCESS:
            return {
                ...state,
                customerAddresses: action.results,
            }
        case tripConstants.SET_REPEAT_TRIP:
            let relatedCustomerOptions
            if (action.isReturn) relatedCustomerOptions = action.tripToReturn.customerToRepeat
            else relatedCustomerOptions = [action.customer]
            return {
                ...state,
                currentCustomer: action.tripToReturn.customerId,
                fullName: action.tripToReturn.fullName,
                favouriteAddresses: [],
                relatedCustomers: relatedCustomerOptions,
            }
        case customerConstants.CUSTOMER_SET_PAGE_TABLE:
            return {
                ...state,
                currentPage: action.page,
            }
        case customerConstants.CUSTOMER_CLEAN_RELATED:
            return {
                ...state,
                relatedCustomers: [],
            }
        case tripConstants.SET_MODIFY_TRIP:
            return {
                ...state,
                favouriteAddresses: [],
                relatedCustomers: action.customers,
            }
        case customerConstants.CUSTOMER_GET_KPI_SUCCESS:
            return {
                ...state,
                pending: false,
                customersKPI: action.results,
            }
        case customerConstants.GET_DOCUMENT_TYPES_SUCCESS:
            return {
                ...state,
                pending: false,
                documentTypes: action.data,
            }
        case customerConstants.SAVE_TABLE_STATE:
            return {
                ...state,
                tableState: action.tableState,
            }
        case userConstants.TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen,
            }
        default:
            return state
    }
}
