import React, { useEffect } from 'react'
import Dashboard from '../../dashboard'
import CustomerNotificationsPush from './CustomerNotificationsPush'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

const CustomerNotifications = (props) => {
    

    return (
        <Dashboard
            component={
                <CustomerNotificationsPush
                    {...props}
                />
            }
        />
    )
}


export default (CustomerNotifications)