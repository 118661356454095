import { vehiclesConstants } from '../../constants/vehicles_constants'
import { driverConstants } from '../../constants/driver_constants'
import { history } from '../../helpers/history'
import { vehiclesService } from '../../services/vehiclesService'
import { alertActions } from '../actions/alert_actions'
import { serviceActions } from '../actions/services_actions'
import i18next from 'i18next'
import { otpService } from '../../services/otpService'
import { trackingService } from '../../services/trackingService'
import { driverService } from '../../services/driverService'
import { serviceService } from '../../services/servicesService'
import { servicesConstants } from '../../constants/services_constants'
import { ERROR_CODES } from '../../constants/types'
import moment from 'moment'
import { ACTION_TYPES } from '../../constants/types'

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value.toString())

const DETAIL_ERROR_CODES = {
    CalendarPeriodsCollapsing: 'CalendarPeriodsCollapsing',
    VehicleHasTrips: 'VehicleHasTrips',
    OTPIdNotFound: 'OTPIdNotFound',
    SamePlateNumberExists: 'SamePlateNumberExists',
    DriverShiftHoursCollapisng: 'DriverShiftHoursCollapisng',
    OTPManagerCannotCreateVehicle: 'OTPManagerCannotCreateVehicle',
    OTPManagerCannotUpdateVehicle: 'OTPManagerCannotUpdateVehicle',
    ShiftHourLimitExceed: 'ShiftHourLimitExceed',
    ShiftHoursCollapisng: 'ShiftHoursCollapisng',
    ProfileCannotInsert: 'ProfileCannotInsert',
    InvalidDocument: 'InvalidDocument',
    OTPExists: 'OTPExists',
    OTPEmailAlreadyExists: 'OTPEmailAlreadyExists',
    VehicleOutOfWorkingDays: 'VehicleOutOfWorkingDays',
    DriverNotValidated: 'DriverNotValidated',
    VehicleAreInServiceLine: 'VehicleAreInServiceLine',
    VehicleDisabled: 'VehicleDisabled',
    VehicleNotDisabled: 'VehicleNotDisabled',
    TripOngoing: 'TripOngoing',
    TripNotCancelled: 'TripNotCancelled',
    Forbidden: 'ForbiddenUpdate'
}

export const vehiclesActions = {
    cleanVehicle,
    getAll,
    getByOtpIdWithOutServices,
    getById,
    insert,
    update,
    disable,
    enable,
    getByOtpId,
    getByServiceId,
    remove,
    getAllBrands,
    getModeldsByBrands,
    clearModels,
    getAllActivesWithLocations,
    unassignDriverVehicle,
    getPrioritiesVehicle,
    setRangeCalendar,
    setExcludeDays,
    removeExcludeDays,
    setCalendar,
    getModeldsByBrandString,
    setVehicleFilter,
    clearVehicle,
    getCalendarDriversKPI,
    saveTableFilters,
}

function cleanVehicle() {
    return dispatch => {
        dispatch(clean())
    }

    function clean() {
        return { type: vehiclesConstants.CLEAN_VEHICLE_STATE }
    }
}

function setCalendar(calendar, idVehicle) {
    return dispatch => {
        dispatch(request())
        vehiclesService.setCalendar(calendar, idVehicle).then(
            result => {
                dispatch(success(result))
                dispatch(alertActions.success(i18next.t('services.services.calendar.success')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.services.calendar.forbiddenSetCalendar'),
                            ),
                        )
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.CalendarPeriodsCollapsing:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.services.calendar.collapseCalendar',
                                            ),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.VehicleHasTrips:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.VehicleHasTrips'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.services.calendar.error'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.SET_CALENDAR_REQUEST }
    }

    function success(calendar) {
        return { type: vehiclesConstants.SET_CALENDAR_SUCCESS, calendar }
    }

    function failure(error) {
        return { type: vehiclesConstants.SET_CALENDAR_FAILURE, error }
    }
}

function removeExcludeDays(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request(index) {
        return { type: vehiclesConstants.REMOVE_EXCLUDE_DAYS, index }
    }
}
function setExcludeDays(range) {
    return dispatch => {
        let rangeObject = {
            startPeriod: moment(range[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),
            finishPeriod: moment(range[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
        }
        dispatch(request(rangeObject))
    }

    function request(element) {
        return { type: vehiclesConstants.SET_EXCLUDE_DAYS, element }
    }
}

function setRangeCalendar(range) {
    return dispatch => {
        dispatch(request(range))
    }

    function request(range) {
        return { type: vehiclesConstants.SET_RANGE_CALENDAR, range }
    }
}

function unassignDriverVehicle(vehicleId) {
    return dispatch => {
        vehiclesService.unassignDriverVehicle(vehicleId).then(
            vehicles => {
                dispatch(getAll())
                dispatch(serviceActions.getWeekDays())
                dispatch(
                    alertActions.success(
                        i18next.t('services.vehicles.unassignDriverVehicleSuccess'),
                    ),
                )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenUpdate')))
                        break
                    default:
                        dispatch(
                            alertActions.error(
                                i18next.t('services.vehicles.unassignDriverVehicleError'),
                            ),
                        )
                }
            },
        )
    }
}

function getAll(filters = null) {
    return dispatch => {
        dispatch(request())
        vehiclesService.getAll().then(
            results => {
                let all = results.slice() //copy only by value
                if (filters != null) {
                    results = filterPlainArray(results, filters)
                }
                dispatch(success(results, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenGetAll')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.OTPIdNotFound:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.OTPIdNotFound'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.getAllError'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }

                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.GETALL_REQUEST }
    }

    function success(results, all) {
        return { type: vehiclesConstants.GETALL_SUCCESS, results, all }
    }

    function failure(error) {
        return { type: vehiclesConstants.GETALL_FAILURE, error }
    }
}

function filterPlainArray(array, filters) {
    const filterKeys = Object.keys(filters)
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true
            return filters[key].find(filter => getValue(filter) === getValue(item[key]))
        })
    })
}

function getByOtpIdWithOutServices(otpId) {
    return dispatch => {
        dispatch(request(otpId))
        vehiclesService.getVehiclesFromOTP(otpId).then(
            vehicles => {
                vehicles = vehicles.filter(v => v.serviceId == 0)
                dispatch(success(vehicles))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.vehicles.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: vehiclesConstants.GETBYOTP_REQUEST, id }
    }

    function success(vehicles) {
        return { type: vehiclesConstants.GETBYOTP_SUCCESS, vehicles }
    }

    function failure(error) {
        return { type: vehiclesConstants.GETBYOTP_FAILURE, error }
    }
}

function getAllBrands() {
    return dispatch => {
        dispatch(request())
        vehiclesService.getAllBrands().then(
            brands => {
                brands.sort((a, b) => a.brandName.localeCompare(b.brandName))
                dispatch(success(brands))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenGetAll')))
                        break
                    default:
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.GETALL_BRAND_REQUEST }
    }

    function success(brands) {
        return { type: vehiclesConstants.GETALL_BRAND_SUCCESS, brands }
    }

    function failure(error) {
        return { type: vehiclesConstants.GETALL_BRAND_FAILURE, error }
    }
}

function getAllActivesWithLocations() {
    return dispatch => {
        //dispatch(request());
        trackingService.getAllVehiclePositionsCurrentDay().then(
            locations => {
                dispatch(success(locations))
                localStorage.setItem('vehicles', JSON.stringify(locations))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenGetAll')))
                        break
                    default:
                        break
                }

                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.GETALL_LOCATION_REQUEST }
    }

    function success(locations) {
        return { type: vehiclesConstants.GETALL_LOCATION_SUCCESS, locations }
    }

    function failure(error) {
        return { type: vehiclesConstants.GETALL_LOCATION_FAILURE, error }
    }
}

function clearModels() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: vehiclesConstants.CLEAR_MODELS }
    }
}

function getModeldsByBrands(brandId) {
    return dispatch => {
        dispatch(request(brandId))
        vehiclesService.getAllModelsByBrand(brandId).then(
            models => {
                models.sort((a, b) => a.model.localeCompare(b.model))
                dispatch(success(models))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenGetAll')))
                        break
                    default:
                        break
                }
                dispatch(clearModels())
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: vehiclesConstants.GET_MODELS_BYBRAND_REQUEST, id }
    }

    function success(models) {
        return { type: vehiclesConstants.GET_MODELS_BYBRAND_SUCCESS, models }
    }

    function failure(error) {
        return { type: vehiclesConstants.GET_MODELS_BYBRAND_FAILURE, error }
    }
}

function getModeldsByBrandString(brand) {
    return dispatch => {
        dispatch(request(brand))
        vehiclesService.getAllModelsByBrandString(brand).then(
            models => {
                models.sort((a, b) => a.model.localeCompare(b.model))
                dispatch(success(models))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenGetAll')))
                        break
                    default:
                        break
                }

                dispatch(clearModels())
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: vehiclesConstants.GET_MODELS_BYBRAND_REQUEST, id }
    }

    function success(models) {
        return { type: vehiclesConstants.GET_MODELS_BYBRAND_SUCCESS, models }
    }

    function failure(error) {
        return { type: vehiclesConstants.GET_MODELS_BYBRAND_FAILURE, error }
    }
}

function getById(id, redirect) {
    return dispatch => {
        dispatch(request(id))
        vehiclesService.getById(id).then(
            vehicle => {
                otpService.getById(vehicle.otpId).then(otp => {
                    dispatch(requestFullName())
                    driverService.getDriversFullName(vehicle.otpId).then(results => {
                        vehicle.otpId = otp
                        results.unshift({
                            id: null,
                            fullName: i18next.t('incidents.details.none'),
                        })
                        if (otp && otp.id) {
                            serviceService.getServicesByOtp(otp.id).then(serviceResults => {
                                serviceResults.unshift({
                                    id: null,
                                    name: i18next.t('incidents.details.none'),
                                })
                                dispatch(successServices(serviceResults))
                                dispatch(successFullNames(results))
                                dispatch(success(vehicle))
                            })
                        }
                        if (redirect) {
                            history.push('/vehicles/' + id)
                        }
                    })
                })
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(i18next.t('services.vehicles.forbiddenGetById')),
                        )
                        break
                    default:
                        alertActions.error(i18next.t('services.vehicles.getByIdError'))
                        break
                }

                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: vehiclesConstants.DETAIL_REQUEST, id }
    }

    function requestFullName() {
        return { type: driverConstants.GET_FULLNAME_REQUEST }
    }

    function success(vehicle) {
        return { type: vehiclesConstants.DETAIL_SUCCESS, vehicle }
    }

    function successFullNames(results) {
        return { type: driverConstants.GET_FULLNAME_SUCCESS, results }
    }

    function successServices(results) {
        return { type: servicesConstants.GETALL_SUCCESS, results }
    }

    function failure(error) {
        return { type: vehiclesConstants.DETAIL_FAILURE, error }
    }
}

function getByOtpId(otpId) {
    return dispatch => {
        dispatch(request(otpId))
        vehiclesService.getVehiclesFromOTP(otpId).then(
            vehicles => {
                dispatch(success(vehicles))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(i18next.t('services.vehicles.forbiddenGetById')),
                        )
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.vehicles.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: vehiclesConstants.GETBYOTP_REQUEST, id }
    }

    function success(vehicles) {
        return { type: vehiclesConstants.GETBYOTP_SUCCESS, vehicles }
    }

    function failure(error) {
        return { type: vehiclesConstants.GETBYOTP_FAILURE, error }
    }
}

function getByServiceId(serviceId) {
    return dispatch => {
        dispatch(request())
        vehiclesService.getVehiclesByService(serviceId).then(
            vehicles => {
                dispatch(success(vehicles))
                if (vehicles.length == 0)
                    dispatch(
                        alertActions.warning(i18next.t('services.vehicles.VehiclesServicesEmpty')),
                    )
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(i18next.t('services.vehicles.forbiddenGetById')),
                        )
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.vehicles.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.GETBYSERVICE_REQUEST }
    }

    function success(vehicles) {
        return { type: vehiclesConstants.GETBYSERVICE_SUCCESS, vehicles }
    }

    function failure(error) {
        return { type: vehiclesConstants.GETBYSERVICE_FAILURE, error }
    }
}

function getPrioritiesVehicle() {
    return dispatch => {
        dispatch(request())
        vehiclesService.getPrioritiesVehicle().then(
            results => {
                let priorities = makeEnum(results)
                dispatch(success(priorities))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(
                            alertActions.error(i18next.t('services.vehicles.forbiddenGetById')),
                        )
                        break
                    default:
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.GET_PRIORITY_REQUEST }
    }

    function success(priorities) {
        return { type: vehiclesConstants.GET_PRIORITY_SUCCESS, priorities }
    }

    function failure(error) {
        return { type: vehiclesConstants.GET_PRIORITY_FAILURE, error }
    }
}

function update(vehicle) {
    return dispatch => {
        let aux = JSON.parse(JSON.stringify(vehicle.shiftDays))
        dispatch(request())
        dispatch(setVehicle(vehicle))
        vehiclesService.update(vehicle).then(
            vehicle => {
                dispatch(success(vehicle))
                history.push('/vehicles')
                dispatch(alertActions.success(i18next.t('services.vehicles.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.SamePlateNumberExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.samePlateNumber'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.DriverShiftHoursCollapisng:
                                    let vehicleError = parsedError.errors.collapsingVehicle[0]
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.vehicles.DriverShiftHoursCollapisng',
                                            ) + vehicleError,
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotCreateVehicle:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.vehicles.OTPManagerCannotCreateVehicle',
                                            ),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotUpdateVehicle:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.vehicles.OTPManagerCannotUpdateVehicle',
                                            ),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.ShiftHourLimitExceed:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.ShiftHourLimitExceed'),
                                        ),
                                    )
                                    vehicle.shiftDays = aux
                                    break
                                case DETAIL_ERROR_CODES.ShiftHoursCollapisng:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.ShiftHoursCollapisng'),
                                        ),
                                    )
                                    vehicle.shiftDays = aux
                                    break
                                case DETAIL_ERROR_CODES.CalendarPeriodsCollapsing:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.vehicles.CalendarPeriodsCollapsing',
                                            ),
                                        ),
                                    )
                                    vehicle.shiftDays = aux
                                    break
                                case DETAIL_ERROR_CODES.ProfileCannotInsert:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.VehicleOutOfWorkingDays'),
                                        ),
                                    )
                                    vehicle.shiftDays = aux
                                    break
                                case DETAIL_ERROR_CODES.InvalidDocument:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.DriverNotValidated'),
                                        ),
                                    )
                                    vehicle.shiftDays = aux
                                    break
                                case DETAIL_ERROR_CODES.OTPExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.VehicleHasTrips'),
                                        ),
                                    )
                                    vehicle.shiftDays = aux
                                    break
                                case DETAIL_ERROR_CODES.OTPEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.VehicleAreInServiceLine') +
                                                parsedError.title,
                                        ),
                                    )
                                    vehicle.shiftDays = aux
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.updateError'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                        dispatch(failure(error))
                }
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.EDIT_REQUEST }
    }

    function success(vehicle) {
        return { type: vehiclesConstants.EDIT_SUCCESS, vehicle }
    }

    function setVehicle(vehicle) {
        return { type: vehiclesConstants.SET_VEHICLE, vehicle }
    }

    function failure(error) {
        return { type: vehiclesConstants.EDIT_FAILURE, error }
    }
}

function enable(vehicleId) {
    return dispatch => {
        dispatch(request());
        vehiclesService.enable(vehicleId).then(
            response => {
                dispatch(success(vehicleId));
                dispatch(alertActions.success(i18next.t('services.vehicles.enableSuccess')));
                dispatch(getAll());
            },
            error => {
                handleCommonErrors(error, dispatch, ACTION_TYPES.ENABLE);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: vehiclesConstants.ENABLE_REQUEST };
    }

    function success(id) {
        return { type: vehiclesConstants.ENABLE_SUCCESS, id };
    }

    function failure(error) {
        return { type: vehiclesConstants.ENABLE_FAILURE, error };
    }
}

function disable(vehicleId) {
    return dispatch => {
        dispatch(request());
        vehiclesService.disable(vehicleId).then(
            response => {
                dispatch(success(vehicleId));
                dispatch(alertActions.success(i18next.t('services.vehicles.disableSuccess')));
                dispatch(getAll());
            },
            error => {
                handleCommonErrors(error, dispatch, ACTION_TYPES.DISABLE);
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return { type: vehiclesConstants.DISABLE_REQUEST };
    }

    function success(id) {
        return { type: vehiclesConstants.DISABLE_SUCCESS, id };
    }

    function failure(error) {
        return { type: vehiclesConstants.DISABLE_FAILURE, error };
    }
}

const handleCommonErrors = (error, dispatch, actionType) => {
    try {
        let parsedError = JSON.parse(error);
        let detailError = parsedError.detail;

        switch (detailError) {
            case DETAIL_ERROR_CODES.VehicleDisabled:
                dispatch(alertActions.error(i18next.t('services.vehicles.alreadyDisabled')));
                break;
            case DETAIL_ERROR_CODES.VehicleNotDisabled:
                dispatch(alertActions.error(i18next.t('services.vehicles.notDisabled')));
                break;
            case DETAIL_ERROR_CODES.TripOngoing:
                dispatch(alertActions.error(i18next.t('services.vehicles.tripOngoing')));
                break;
            case DETAIL_ERROR_CODES.TripNotCancelled:
                dispatch(alertActions.error(i18next.t('services.vehicles.tripNotCancelled')));
                break;
            case DETAIL_ERROR_CODES.Forbidden:
                dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenUpdate')));
                break;
            default:
                dispatch(alertActions.error(
                    actionType === ACTION_TYPES.ENABLE
                        ? i18next.t('services.vehicles.enableError')
                        : i18next.t('services.vehicles.disableError')
                ));
        }
    } catch (parseError) {
        dispatch(alertActions.error(
            actionType === ACTION_TYPES.ENABLE
                ? i18next.t('services.vehicles.enableError')
                : i18next.t('services.vehicles.disableError')
        ));
        console.error('Error parsing response:', parseError);
    }
};

function clearResults() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: vehiclesConstants.CLEAR_RESULTS }
    }
}

function insert(vehicle) {
    return dispatch => {
        dispatch(request())
        vehiclesService.insert(vehicle).then(
            vehicle => {
                dispatch(success(vehicle))
                dispatch(alertActions.success(i18next.t('services.vehicles.insertSuccess')))
                history.push('/vehicles')
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenInsert')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.SamePlateNumberExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.samePlateNumber'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotCreateVehicle:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.vehicles.OTPManagerCannotCreateVehicle',
                                            ),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPManagerCannotUpdateVehicle:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.vehicles.OTPManagerCannotUpdateVehicle',
                                            ),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.ShiftHourLimitExceed:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.ShiftHourLimitExceed'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.ShiftHoursCollapisng:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.ShiftHoursCollapisng'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.DriverShiftHoursCollapisng:
                                    let vehicleError = parsedError.errors.collapsingVehicle[0]
                                    dispatch(
                                        alertActions.error(
                                            i18next.t(
                                                'services.vehicles.DriverShiftHoursCollapisng',
                                            ) + vehicleError,
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.ProfileCannotInsert:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.ProfileCannotInsert'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.InvalidDocument:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.InvalidDocument'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.OTPExists'),
                                        ),
                                    )
                                    break
                                case DETAIL_ERROR_CODES.OTPEmailAlreadyExists:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.OTPEmailAlreadyExists'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.insertError'),
                                        ),
                                    )
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.ADD_REQUEST }
    }

    function success(vehicle) {
        return { type: vehiclesConstants.ADD_SUCCESS, vehicle }
    }

    function failure(error) {
        return { type: vehiclesConstants.ADD_FAILURE, error }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        vehiclesService._delete(id).then(
            vehicles => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.vehicles.deleteSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.vehicles.forbiddenDelete')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.VehicleHasTrips:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.VehicleHasTrips'),
                                        ),
                                    )
                                    break
                                default:
                                    dispatch(
                                        alertActions.error(
                                            i18next.t('services.vehicles.deleteError'),
                                        ),
                                    )
                                    break
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }

                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: vehiclesConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: vehiclesConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: vehiclesConstants.DELETE_FAILURE, error }
    }
}

function setVehicleFilter(prop, values, filters) {
    return dispatch => {
        dispatch(request(prop, values, filters))
    }

    function request(prop, values, filters) {
        return { type: vehiclesConstants.SET_FILTER_VEHICLE, prop, values, filters }
    }
}

function clearVehicle() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: vehiclesConstants.CLEAR_VEHICLE }
    }
}

function getCalendarDriversKPI() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            vehiclesService.getCalendarDriversKPI().then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    switch (error) {
                        case ERROR_CODES.forbidden:
                            dispatch(
                                alertActions.error(i18next.t('services.trips.forbiddenGetAll')),
                            )
                            break
                        default:
                            dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                            break
                    }

                    dispatch(failure(error))
                    reject('error')
                },
            )
        })
    }

    function request() {
        return { type: vehiclesConstants.GET_CALENDAR_DRIVERS_KPI_REQUEST }
    }

    function success(results) {
        return { type: vehiclesConstants.GET_CALENDAR_DRIVERS_KPI_SUCCESS, results }
    }

    function failure(error) {
        return { type: vehiclesConstants.GET_CALENDAR_DRIVERS_KPI_FAILURE, error }
    }
}

function makeEnum(enumObject) {
    var all = []
    for (var key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        })
    }
    return all
}

function saveTableFilters(tableFilters) {
    return dispatch => {
        dispatch(save(tableFilters))
    }

    function save(tableFilters) {
        return { type: vehiclesConstants.SAVE_TABLE_FILTERS, tableFilters }
    }
}

function cleanFilters() {
    return dispatch => {
        dispatch(clean())
    }

    function clean() {
        return { type: vehiclesConstants.CLEAN_FILTER_VEHICLE }
    }
}
