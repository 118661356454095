import React from 'react'
import GoogleMapReact from 'google-map-react'
import stringConstants from '../../constants/strings'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import moment from 'moment'
import FilterMarkers from './filters'
import ReactHtmlParser from 'react-html-parser'
import ReactDOM from 'react-dom'
import { history } from '../../helpers/history'
import i18next from 'i18next'
import store from '../../redux/store'
import { alertActions } from '../../redux/actions/alert_actions'
import { factory } from '../../helpers/factory'
import { withSnackbar } from 'notistack'
import "./styles.css"

export class MapsServiceView extends React.Component {
    static defaultProps = {
        center: {
            lat: 41.390205,
            lng: 2.154007,
        },
        zoom: 11,
    }

    constructor(props) {
        super()
        this.state = {
            vehicles: props.vehicleReducer.locations,
            markers: [],
            otp: '',
            service: '',
            plate: '',
            zone: '',
            filteredOtp: [],
            filteredService: [],
            filteredPlate: [],
            filteredZone: [],
            onMouseOverVehicle: null,
        }
        this.renderMarkers = this.renderMarkers.bind(this)
        this.setFilter = this.setFilter.bind(this)
        this.cleanMarkers = this.cleanMarkers.bind(this)
        this.refreshMarkers = this.refreshMarkers.bind(this)
        this.handleMarkerClick = this.handleMarkerClick.bind(this)
        this.handleGetCurrentCapacity = this.handleGetCurrentCapacity.bind(this)
        this.mountStringInfowindow = this.mountStringInfowindow.bind(this)
    }

    handleGetCurrentCapacity(vehicleId) {
        this.setState({ onMouseOverVehicle: vehicleId })
        // vehicleId &&
        //   vehicleId != this.state.onMouseOverVehicle &&
        //   this.props.getAvailableVehicleCapacity(vehicleId);
    }

    mountStringInfowindow(element) {
        ReactHtmlParser(this.formatString(element))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.onMouseOverVehicle != this.state.onMouseOverVehicle) {
            this.props.getAvailableVehicleCapacity(
                this.state.onMouseOverVehicle,
            )
            return true
        } else {
            return false
        }
    }

    setFilter(name, value) {
        this.setState({ [name]: value }, () => {
            this.state.markers.forEach(element => {
                const item = element.item;

                const otpCondition = this.state.otp.length === 0 || this.state.otp.includes(item.otpId);
                const serviceCondition = this.state.service.length === 0 || this.state.service.includes(item.serviceId);
                const plateCondition = this.state.plate.length === 0 || this.state.plate.includes(item.plateNumber);
                debugger;
                const zoneCondition = Array.isArray(this.state.zone) && this.state.zone.length > 0
                    ? this.state.zone.some(zone =>
                        factory._isInPolygon(
                            { lat: item.lat, lng: item.lon },
                            zone
                        )
                    )
                    : true;

                const isVisible = otpCondition && serviceCondition && plateCondition && zoneCondition;

                item.setVisible(isVisible);
            });

            const onlyMarkers = this.state.markers
                .filter(marker => marker.item.visible)
                .map(marker => marker.item);

            const cluster = this.state.cluster;
            if (cluster) {
                cluster.setMap(null);
                cluster.clearMarkers();
            }

            this.clusterMarkers(onlyMarkers, this.state.map);
        });
    }

    renderMarkers(map, maps) {
        let contentString = ''

        let url_available =
            process.env.PUBLIC_URL + '/content/img/bus_available.png'
        let url_unavailable =
            process.env.PUBLIC_URL + '/content/img/bus_unavailable.png'

        let iconAvailable = {
            url: url_available,
            scaledSize: new maps.Size(40, 40),
        }
        let iconUnavailable = {
            url: url_unavailable,
            scaledSize: new maps.Size(40, 40),
        }

        let infoWindow = new maps.InfoWindow({
            content: contentString,
        })
        let markers = []
        const { t, zoneReducer } = this.props
        this.cleanMarkers()
        this.state.vehicles.forEach(element => {
            let marker = new maps.Marker({
                position: new maps.LatLng(element.latitude, element.longitude),
                animation: maps.Animation.DROP,
                brand: element.brand,
                lat: element.latitude,
                lon: element.longitude,
                model: element.model,
                plateNumber: element.plateNumber,
                serviceId: element.serviceId,
                otpId: element.otpId,
                otpName: element.otpName,
                serviceName: element.serviceName,
                driverName: element.driverName,
                icon: element.isRecent ? iconAvailable : iconUnavailable,
                map,
            })
            const item = { id: element.vehicleId, item: marker }
            markers.push(item)
            this.mountStringInfowindow(element)

            let newDiv = document.createElement('div')

            marker.addListener('click', () => {
                if (
                    this.props.vehicleReducer.currentAvailableVehicleCapacity !=
                    null
                ) {
                    contentString = this.formatString(
                        element,
                        this.props.vehicleReducer
                            .currentAvailableVehicleCapacity,
                    )
                    const button = (
                        <div>
                            {ReactHtmlParser(contentString)}
                            <div
                                style={{ textAlign: 'center', paddingTop: 10 }}
                            >
                                <button
                                    onClick={() =>
                                        this.handleMarkerClick(element)
                                    }
                                >
                                    {i18next.t('maps.page.chat')}
                                </button>
                            </div>
                        </div>
                    )
                    ReactDOM.render(React.Children.only(button), newDiv)
                    infoWindow.setContent(newDiv)
                    infoWindow.open(map, marker)
                } else {
                    this.handleGetCurrentCapacity(element.vehicleId)
                }
            })

            maps.event.addListener(marker, 'mouseover', () => {
                this.handleGetCurrentCapacity(element.vehicleId)
            })
        })

        zoneReducer.allStops.forEach((element) => {
            let marker = new maps.Marker({
                position: new maps.LatLng(element.point.lat, element.point.lon),
                animation: maps.Animation.DROP,
                lat: element.point.lat,
                lon: element.point.lon,
                map,
            })
            maps.event.addListener(
                marker,
                'mouseover',
                (function (marker, contentString, infoWindow) {
                    return function () {
                        infoWindow.setContent(contentString)
                        infoWindow.open(map, marker)
                    }
                })(marker, element.name, infoWindow),
            )
            maps.event.addListener(marker, 'mouseout', function () {
                infoWindow.close()
            })
            const item = { id: element.id, item: marker }
            markers.push(item)
        })

        const onlyMarkers = markers.map(x => x.item)
        this.clusterMarkers(onlyMarkers, map)
        zoneReducer.results.forEach(element => {
            const points = []
            element.points.forEach(point => {
                points.push({
                    lat: point.lat,
                    lng: point.lon,
                })
            })
            const zone = new maps.Polygon({
                paths: points,
                strokeColor: '#0000FF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#0000FF',
                fillOpacity: 0.35,
            })
            zone.addListener('mouseover', () => {
                this.props.closeSnackbar()
                store.dispatch(alertActions.info(element.name))
            })
            zone.addListener('mouseout', () => {
                this.props.closeSnackbar()
                store.dispatch(alertActions.clear())
            })
            zone.setMap(map)
        })
        let intervalId = setInterval(
            () => this.refreshMarkers(maps, map, markers, infoWindow),
            3000,
        )
        this.setState({ intervalId: intervalId, markers: markers, map: map })
    }

    refreshMarkers(maps, map, markers, infoWindow) {
        const { t } = this.props
        var contentString = ''
        const vehicles = JSON.parse(localStorage.getItem('vehicles'))
        var url_available =
            process.env.PUBLIC_URL + '/content/img/bus_available.png'
        var url_unavailable =
            process.env.PUBLIC_URL + '/content/img/bus_unavailable.png'

        var iconAvailable = {
            url: url_available,
            scaledSize: new maps.Size(40, 40),
        }
        var iconUnavailable = {
            url: url_unavailable,
            scaledSize: new maps.Size(40, 40),
        }
        let newDiv = document.createElement('div')
        if (vehicles != null) {
            vehicles.forEach(element => {
                if (
                    markers.filter(x => x.id === element.vehicleId)[0] != null
                ) {
                    const marker = markers.filter(
                        x => x.id === element.vehicleId,
                    )[0].item
                    marker.setPosition(
                        new maps.LatLng(element.latitude, element.longitude),
                    )
                    marker.setMap(map)
                    marker.setIcon(
                        element.isRecent ? iconAvailable : iconUnavailable,
                    )
                    this.mountStringInfowindow(element)

                    marker.addListener('click', () => {
                        if (
                            this.props.vehicleReducer
                                .currentAvailableVehicleCapacity != null
                        ) {
                            contentString = this.formatString(
                                element,
                                this.props.vehicleReducer
                                    .currentAvailableVehicleCapacity,
                            )
                            const button = (
                                <div>
                                    {ReactHtmlParser(contentString)}
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            paddingTop: 10,
                                        }}
                                    >
                                        <button
                                            onClick={() =>
                                                this.handleMarkerClick(element)
                                            }
                                        >
                                            {i18next.t('maps.page.chat')}
                                        </button>
                                    </div>
                                </div>
                            )
                            ReactDOM.render(React.Children.only(button), newDiv)
                            infoWindow.setContent(newDiv)
                            infoWindow.open(map, marker)
                        } else {
                            this.handleGetCurrentCapacity(element.vehicleId)
                        }
                    })

                    maps.event.addListener(marker, 'mouseover', () => {
                        this.handleGetCurrentCapacity(element.vehicleId)
                    })
                } else {
                    let marker = new maps.Marker({
                        position: new maps.LatLng(
                            element.latitude,
                            element.longitude,
                        ),
                        animation: maps.Animation.DROP,
                        brand: element.brand,
                        model: element.model,
                        lat: element.latitude,
                        lon: element.longitude,
                        plateNumber: element.plateNumber,
                        serviceId: element.serviceId,
                        otpId: element.otpId,
                        otpName: element.otpName,
                        serviceName: element.serviceName,
                        driverName: element.driverName,
                        icon: element.isRecent
                            ? iconAvailable
                            : iconUnavailable,
                        map,
                    })
                    const item = { id: element.vehicleId, item: marker }
                    markers.push(item)
                    this.mountStringInfowindow(element)

                    marker.addListener('click', () => {
                        contentString = this.formatString(
                            element,
                            this.props.vehicleReducer
                                .currentAvailableVehicleCapacity,
                        )
                        if (
                            this.props.vehicleReducer
                                .currentAvailableVehicleCapacity != null
                        ) {
                            const button = (
                                <div>
                                    {ReactHtmlParser(contentString)}
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            paddingTop: 10,
                                        }}
                                    >
                                        <button
                                            onClick={() =>
                                                this.handleMarkerClick(element)
                                            }
                                        >
                                            {i18next.t('maps.page.chat')}
                                        </button>
                                    </div>
                                </div>
                            )
                            ReactDOM.render(React.Children.only(button), newDiv)
                            infoWindow.setContent(newDiv)
                            infoWindow.open(map, marker)
                        } else {
                            this.handleGetCurrentCapacity(element.vehicleId)
                        }
                    })

                    maps.event.addListener(marker, 'mouseover', () => {
                        this.handleGetCurrentCapacity(element.vehicleId)
                    })
                    const onlyMarkers = markers.map(x => x.item)
                    this.clusterMarkers(onlyMarkers, map)
                }
            })
            const onlyMarkers = markers.map(x => x.item)
            const cluster = this.state.cluster
            cluster.setMap(null)
            this.clusterMarkers(onlyMarkers, map)
            this.state.markers = markers
        }
    }

    formatString(element, capacity) {
        return (
            '<b>' +
            i18next.t('maps.page.brand') +
            '</b>' +
            element.brand +
            ' <br>' +
            '<b>' +
            i18next.t('maps.page.model') +
            '</b>' +
            element.model +
            ' <br>' +
            '<b>' +
            i18next.t('maps.page.plate') +
            '</b>' +
            element.plateNumber +
            ' <br>' +
            '<b>' +
            i18next.t('maps.page.otp') +
            '</b>' +
            element.otpName +
            ' <br>' +
            '<b>' +
            i18next.t('maps.page.service') +
            '</b>' +
            element.serviceName +
            ' <br>' +
            '<b>' +
            i18next.t('maps.page.driver') +
            '</b>' +
            element.driverName +
            ' <br>' +
            '<b>' +
            i18next.t('maps.page.lastConnection') +
            '</b>' +
            moment(element.date).format('DD/MM/YYYY HH:mm') +
            ' <br>' +
            '<b>' +
            i18next.t('maps.page.availableCapacity') +
            ' </b>' +
            capacity || 'n/a' + ' <br>'
        )
    }

    handleMarkerClick(element) {
        // console.log("handleMarkerClick", element);
        const { driverId, driverName } = element
        //process.env.REACT_APP_ENV === "dev" &&
        history.push(`/chat/:${driverId}`)
    }

    clusterMarkers(markers, map) {
        this.setState({
            cluster: new MarkerClusterer(
                map,
                markers.filter(x => x.visible), {
                clusterClass: 'custom-clustericon',
                styles: [
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon-1",
                    },
                    {
                        width: 35,
                        height: 35,
                        className: "custom-clustericon-2",
                    },
                    {
                        width: 40,
                        height: 40,
                        className: "custom-clustericon-3",
                    }
                ]
            })
        })
    }

    cleanMarkers() {
        let markers = this.state.markers
        for (const element of markers) {
            element.setMap(null)
        }
        markers = []
        const cluster = this.state.cluster
        if (cluster)
            cluster.clearMarkers();
        this.setState({ markers })
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }
    render() {
        const { zoneReducer, vehicleReducer } = this.props
        return (
            <div style={{ height: '100vh', overflow: 'hidden', width: '100%' }}>
                {!zoneReducer.pending && !vehicleReducer.pending && (
                    <React.Fragment>
                        <FilterMarkers
                            {...this.props}
                            setFilter={this.setFilter}
                            filteredOtp={this.state.filteredOtp}
                            filteredService={this.state.filteredService}
                            filteredPlate={this.state.filteredPlate}
                            filteredZone={this.state.filteredZone}
                        />
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: stringConstants.API_Key_GoogleMaps,
                                libraries: ['drawing,places'],
                                v: '3.30',
                            }}
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                            yesIWantToUseGoogleMapApiInternals={true}
                            onGoogleApiLoaded={({ map, maps }) =>
                                this.renderMarkers(map, maps)
                            }
                        ></GoogleMapReact>
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default withSnackbar(MapsServiceView)
