import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { tripActions } from '../../../redux/actions/trip_actions';
import { customerActions } from '../../../redux/actions/customer_actions';
import ResumeTripView from './page'
import { ResumeTripWrapped } from './details';
import { multipleTripsActions } from '../../../redux/actions/multipleTrips_action';
import { is } from 'date-fns/locale';

class ResumeTrip extends React.Component {

    constructor(props) {
        super();
        this.state = {
            originAddress: '',
            destinationAddress: '',
            pickupTime: '',
            dropOffTime: '',
            price: '',
            customerName: '',
            resolve: false,
            numPassengers: '',
            luggage: '',
            hasWheelChair: false,
            isOnRelatedCustomerBehalf: false,
            isPMR: false,
        }
    }

    componentDidMount() {
        if (this.props.tripReducer.trip != null)
            this.coordsToAddress();
            this.fetchCustomerInfo();
    }

    componentDidUpdate(prevProps) {
        if (this.props.tripReducer.trip != prevProps.tripReducer.trip) {
            if (this.props.tripReducer.trip != null) {
                this.coordsToAddress()
                this.fetchCustomerInfo();
            }
        }

        if (this.props.customerReducer.customer !== prevProps.customerReducer.customer) {
            const isPMR = this.props.customerReducer.customer?.isPMR || false;
            this.setState({ isPMR });
        }
    }

    componentWillUnmount() {
        this.props.cleanMultiTrip()
        this.props.cleanTrip()
    }

    fetchCustomerInfo() {
        const customerId = this.props.tripReducer.trip?.customerId;
        if (customerId) {
            this.props.getById(customerId, false);
        }
    }

    async coordsToAddress() {
        let originAddress = this.props.tripReducer.trip.pickUpLocation.address;
        let pickUpStopName = this.props.tripReducer.trip.pickUpStopName;
        let dropOffStopName = this.props.tripReducer.trip.dropOffStopName;
        let destinationAddress = this.props.tripReducer.trip.dropOffLocation.address;
        let customerName = this.props.tripReducer.trip.customerName;
        let pickupTime = moment(this.props.tripReducer.trip.schedulePickUpTime).format("DD/MM/YYYY HH:mm")
        let dropOffTime = moment(this.props.tripReducer.trip.scheduleDropOffTime).format("DD/MM/YYYY HH:mm")
        let price = this.props.tripReducer.trip.price?.amount
        let tariff = this.props.tripReducer.trip.userTariff
        let numPassengers = this.props.tripReducer.trip.numPassengers
        let luggage = this.props.tripReducer.trip.luggage
        let hasWheelChair = this.props.tripReducer.trip.hasWheelChair
        let isOnRelatedCustomerBehalf = this.props.tripReducer.trip.isOnRelatedCustomerBehalf
        let isPMR = this.props.tripReducer.trip.isPMR
        this.setState({
            originAddress,
            destinationAddress,
            pickupTime,
            dropOffTime,
            resolve: true,
            price,
            pickUpStopName,
            dropOffStopName,
            customerName,
            tariff,
            numPassengers,
            luggage,
            hasWheelChair,
            isOnRelatedCustomerBehalf,
            isPMR
        })
    }

    render() {
        return (
            <ResumeTripView
                originAddress={this.state.originAddress}
                destinationAddress={this.state.destinationAddress}
                pickupTime={this.state.pickupTime}
                dropOffTime={this.state.dropOffTime}
                price={this.state.price}
                customerName={this.state.customerName}
                pickUpStopName={this.state.pickUpStopName}
                dropOffStopName={this.state.dropOffStopName}
                tariff={this.state.tariff}
                numPassengers={this.state.numPassengers}
                luggage={this.state.luggage}
                hasWheelChair={this.state.hasWheelChair}
                isOnRelatedCustomerBehalf={this.state.isOnRelatedCustomerBehalf}
                isPMR={this.state.isPMR}
                loading={!this.state.resolve || this.props.multipleTripReducer.pending}
                hasMultipleTrips={this.props.multipleTripReducer.executeMultipleTrips}
                {...this.props}
            />
        )
    }

}

function mapState(state) {
    const { tripReducer, multipleTripReducer, customerReducer } = state;
    return { tripReducer, multipleTripReducer, customerReducer };
}

const actionCreators = {
    clearAllMarkers: tripActions.clearAllMarkers,
    clearTrip: tripActions.clear,
    acceptTrip: tripActions.acceptTrip,
    rejectTrip: tripActions.rejectTrip,
    acceptModifyTrip: tripActions.acceptModifyTrip,
    cancelTripPlanned: multipleTripsActions.cancelTripPlanned,
    acceptTripReturn: multipleTripsActions.acceptTripReturn,
    setOriginalReturn: multipleTripsActions.setOriginalReturn,
    cleanMultiTrip: multipleTripsActions.cleanMultiTrip,
    cleanTrip: tripActions.cleanTrip,
    getById: customerActions.getById,
}

export default compose(withTranslation('common'), connect(mapState, actionCreators), ResumeTripWrapped)(ResumeTrip);